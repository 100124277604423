import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import SEO from "../components/SEO";

export default function WebinarPage({ data: { webinar } }) {
  return (
    <>
      <SEO title={webinar.name} image={webinar.image.asset.src} />
      <div className="center">
        <Img
          style={{
            maxWidth: "700px",
            width: "75%",
            // maxHeight: 300,
            margin: "auto",
            marginBottom: 20,
          }}
          fluid={webinar.image.asset.fluid}
        />
        <h2>
          <span className="bold">{webinar.name}</span>
        </h2>
        <p className="">{webinar.shortDescription}</p>
        <p
          style={{
            maxWidth: "90%",
            margin: "50px auto",
            wordBreak: "break-word",
          }}
        >
          {webinar.longDescription}
        </p>
      </div>
    </>
  );
}

export const query = graphql`
  query($slug: String!) {
    webinar: sanityWebinar(slug: { current: { eq: $slug } }) {
      name
      price
      shortDescription
      longDescription

      image {
        asset {
          url
          fluid(maxWidth: 1000, maxHeight: 750) {
            ...GatsbySanityImageFluid
          }
          metadata {
            lqip
          }
        }
      }
    }
  }
`;
